import React, { createContext, useEffect, useState } from 'react';
import { apiCredentials } from '../services/http';
import { authValidateToken } from '../services/http/requests';

export const DeviceAuthContext = createContext({
  deviceType: undefined,
  setDeviceType: () => {},
  isAuthenticated: false,
  setIsAuthenticated: () => {},
  isAuthenticating: false,
});

const DevicesAuthProvider = ({ children }) => {
  const [deviceType, setDeviceType] = useState(null);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isAuthenticating, setIsAuthenticating] = useState(true);
  const validateToken = async () => {
    const accessToken = await apiCredentials.getAccessToken();
    if (!accessToken) {
      setIsAuthenticating(false);
      return;
    }
    setIsAuthenticating(true);
    try {
      await authValidateToken();
      setIsAuthenticated(true);
    } catch (err) {
      setIsAuthenticated(false);
      await apiCredentials.clear();
    } finally {
      setIsAuthenticating(false);
    }
  };

  useEffect(() => {
    validateToken();
  }, []);

  return (
    <DeviceAuthContext.Provider
      value={{
        deviceType,
        setDeviceType,
        isAuthenticated,
        setIsAuthenticated,
        isAuthenticating,
      }}
    >
      {children}
    </DeviceAuthContext.Provider>
  );
};

export default DevicesAuthProvider;
