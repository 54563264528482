import React from 'react';
import { Navigate, useRoutes } from 'react-router-dom';
import MainPage from '../pages/MainPage';
import PasswordPage from '../pages/PasswordPage';
import DeviceTypesPage from '../pages/DeviceTypesPage';
import SpacesPage from '../pages/SpacesPage';
import { AuthGuard, GuestGuard } from '../auth';
import { PATH_APP } from './paths';

export default function Router() {
  return useRoutes([
    { path: '/', element: <MainPage /> },
    {
      path: '/:tenant',
      children: [
        { index: true, element: <Navigate to={'connect'} relative={'route'} /> },
        {
          path: 'connect',
          element: (
            <GuestGuard>
              <PasswordPage />
            </GuestGuard>
          ),
          index: true,
        },
        {
          path: 'type',
          element: (
            <AuthGuard>
              <DeviceTypesPage />
            </AuthGuard>
          ),
        },
        {
          path: 'space',
          element: (
            <AuthGuard>
              <SpacesPage />
            </AuthGuard>
          ),
        },
      ],
    },
    { path: '*', element: <Navigate to={PATH_APP.root} /> },
  ]);
}
