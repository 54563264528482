import React, { useContext, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import '../../styles/common-styles.scss';
import MainLayout from '../../components/MainLayout';
import SearchField from './SearchField';
import './styles.scss';
import { MoonLoader, PulseLoader } from 'react-spinners';
import classnames from 'classnames';
import { DeviceAuthContext } from '../../providers/DevicesAuthProvider';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { PATH_APP } from '../../routes';
import { devicesConnect, getPlacesList, getZonesList } from '../../services/http/requests';
import { DeviceType } from '@inspace-org/common';
import ChevronRightIcon from '../../icons/ChevronRightIcon';
import ArrowLeftIcon from '../../icons/ArrowLeftIcon';

const SpacesPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { tenant } = useParams();
  const { deviceType } = useContext(DeviceAuthContext);
  const [spaces, setSpaces] = useState([]);
  const [places, setPlaces] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedEntityId, setSelectedEntityId] = useState('');
  const [isSelectingPlace, setIsSelectingPlace] = useState(false);
  const [search, setSearch] = useState('');

  const isPlaceSelector = deviceType === DeviceType.PHONE;

  const requestSpaces = async () => {
    setIsLoading(true);
    try {
      let [zones, places] = await Promise.all([getZonesList(), getPlacesList()]);
      zones = zones.sort((a, b) => a.name.localeCompare(b.name));
      zones.unshift({ _id: null, name: isPlaceSelector ? t('place_without_space') : t('reception') });
      places = places.sort((a, b) => a.title.localeCompare(b.title));
      setSpaces(zones);
      setPlaces(places);
    } catch (err) {
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    requestSpaces();
  }, []);

  const handleSelectSpaceClick = (space) => {
    setSearch('');
    setSelectedEntityId(space._id);
    setIsSelectingPlace(true);
  };

  const handleBackClick = () => {
    if (isSelectingPlace) {
      setIsSelectingPlace(false);
      setSearch('');
      setSelectedEntityId('');
    } else {
      navigate(PATH_APP.type(tenant));
    }
  };

  const handleConnectClick = async (entity) => {
    try {
      const dto = { type: deviceType };
      if (isPlaceSelector) {
        setSelectedEntityId(entity.id);
        dto.place = entity.id;
      } else {
        setSelectedEntityId(entity._id);
        dto.zone = entity._id;
      }
      const href = await devicesConnect(dto);
      window.location.replace(href);
    } catch (err) {
      console.error(err);
      setSelectedEntityId('');
    }
  };

  if (!deviceType) {
    return <Navigate to={PATH_APP.type(tenant)} replace />;
  }

  return (
    <MainLayout>
      <div className={classnames('spaces', 'wrapper', isLoading ? 'loading' : '')}>
        <h1 className="win-title">
          <ArrowLeftIcon className="win-title__icon" onClick={handleBackClick} />
          {isSelectingPlace ? <Trans i18nKey="places-title" /> : <Trans i18nKey="spaces-title" />}
        </h1>

        <p className="win-description">
          {isSelectingPlace ? <Trans i18nKey="places-description" /> : <Trans i18nKey="spaces-description" />}
        </p>

        <SearchField label={t('space-search-label')} value={search} onChange={(value) => setSearch(value)} />

        <div className={'spaces__loader'}>
          <MoonLoader loading={isLoading} />
        </div>

        {isSelectingPlace ? (
          <>
            <ul className="spaces__select wrapper">
              {places
                .filter((place) => {
                  if (place.zone !== selectedEntityId) return false;
                  if (search) {
                    return place.title.toLowerCase().includes(search.toLowerCase().trim());
                  }
                  return true;
                })
                .map((place) => {
                  return (
                    <li key={place.id} className="spaces__select__space">
                      <p className="spaces__select__space__name">
                        <span>{place.title}</span>
                      </p>
                      <button
                        className="spaces__select__space__btn"
                        type={'button'}
                        onClick={() => handleConnectClick(place)}
                      >
                        <PulseLoader loading={selectedEntityId === place.id} size={8} color={'#b9b9b9'} />
                        {selectedEntityId !== place.id && <Trans i18nKey={'connect'} />}
                      </button>
                    </li>
                  );
                })}
            </ul>
          </>
        ) : (
          <ul className="spaces__select wrapper">
            {spaces
              .filter((space) => {
                if (!space._id && !isPlaceSelector) return false;
                if (search) {
                  return space.name.toLowerCase().includes(search.toLowerCase().trim());
                }
                return true;
              })
              .map((space) => {
                return isPlaceSelector ? (
                  <li
                    key={space._id}
                    className="spaces__select__space clickable"
                    onClick={() => handleSelectSpaceClick(space)}
                  >
                    <p className="spaces__select__space__name">
                      <span>{space.name}</span>
                    </p>
                    <div className="spaces__select__space__btn">
                      <ChevronRightIcon />
                    </div>
                  </li>
                ) : (
                  <li key={space._id} className="spaces__select__space">
                    <p className="spaces__select__space__name">
                      <span>{space.name}</span>
                    </p>
                    <button
                      className="spaces__select__space__btn"
                      type={'button'}
                      disabled={selectedEntityId !== ''}
                      onClick={() => handleConnectClick(space)}
                    >
                      <PulseLoader loading={selectedEntityId === space._id} size={8} color={'#b9b9b9'} />
                      {selectedEntityId !== space._id && <Trans i18nKey={'connect'} />}
                    </button>
                  </li>
                );
              })}
          </ul>
        )}
      </div>
    </MainLayout>
  );
};

export default SpacesPage;
