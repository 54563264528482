import React from 'react';
import { Trans } from 'react-i18next';
import MainLayout from '../../components/MainLayout';

export default function MainPage() {
  return (
    <MainLayout>
      <p style={{ padding: '2em' }}>
        <Trans i18nKey={'no_tenant_error'}>
          <a href="https://my.inspace.app"></a>
        </Trans>
      </p>
    </MainLayout>
  );
}
