import { api } from './index';

/**
 * @param {string} tenant
 * @param {string} password
 * @returns {Promise<string>} accessToken
 * */
export async function authDeviceManager(tenant, password) {
  const { accessToken } = await api.post('/v1/auth/device-manager', { tenant, password });
  return accessToken;
}

export async function authValidateToken() {
  return api.get('/v1/auth/check');
}

export async function getZonesList() {
  const { zones } = await api.get('/v1/zones');
  return zones;
}

export async function getPlacesList() {
  const { items } = await api.get('/v2/places');
  return items;
}

/**
 * @param {object} dto
 * @param {string|null} [dto.zone] - Zone id or null (for reception)
 * @param {string|null} [dto.place] - Place id (if device type is "phone")
 * @param {"panel"|"tablet"|"phone"} dto.type - device type
 * */
export async function devicesConnect(dto) {
  const { href } = await api.post('/v2/admin/devices/connect', dto);
  return href;
}
