import { normalizeApiUrl } from './services/utils/normalizeApiUrl';

const env = {
  publicUrl: process.env.PUBLIC_URL || '',
  apiUrl: normalizeApiUrl(process.env.REACT_APP_API_URL),
  locale: {
    force: process.env.REACT_APP_LOCALE_FORCE,
  },
};

export default env;
