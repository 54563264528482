import ru from './ru.json';
import en from './en.json';
import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import env from '../env';

let language = 'en';
if (window.navigator.language.slice(0, 2) === 'ru') {
  language = 'ru';
}
if (env.locale.force) {
  language = env.locale.force;
}

i18next.use(initReactI18next).init({
  lng: language,
  fallbackLng: 'en',
  interpolation: {
    escapeValue: false,
  },
  react: {
    transSupportBasicHtmlNodes: true,
    transKeepBasicHtmlNodesFor: ['br', 'strong', 'b', 'i', 'p', 'u', 'a'],
  },
  resources: {
    en: { translation: { ...en } },
    ru: { translation: { ...ru } },
  },
});

export const DATE_OFFSET_TZ = (() => {
  let off = new Date().getTimezoneOffset();
  let tz = window.Intl?.DateTimeFormat()?.resolvedOptions()?.timeZone || '';
  return `${off},${tz}`;
})();

export { language, i18next };
