import { useContext } from 'react';
import { DeviceAuthContext } from '../providers/DevicesAuthProvider';
import { Navigate, useParams } from 'react-router-dom';
import { PATH_APP } from '../routes';

export default function GuestGuard({ children }) {
  const { isAuthenticating, isAuthenticated } = useContext(DeviceAuthContext);
  const { tenant } = useParams();
  if (isAuthenticating) {
    return <></>;
  }
  if (isAuthenticated) {
    return <Navigate to={PATH_APP.type(tenant)} replace={true} />;
  }

  return <>{children}</>;
}
