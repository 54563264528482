import React, { useContext, useState } from 'react';
import { Trans } from 'react-i18next';
import { DeviceAuthContext } from '../../providers/DevicesAuthProvider';
import '../../styles/common-styles.scss';
import ActionButton from '../../components/ActionButton';
import MainLayout from '../../components/MainLayout';
import { useNavigate, useParams } from 'react-router-dom';
import './styles.scss';
import OkIcon from '../../icons/OkIcon';
import { PATH_APP } from '../../routes';
import { DeviceType } from '@inspace-org/common';
import env from '../../env';
import { devicesConnect } from '../../services/http/requests';

const SpaceType = {
  MEETING_ROOM: 'meeting_room',
  RECEPTION: 'reception',
};
const DeviceTypesPage = () => {
  const navigate = useNavigate();
  const { deviceType, setDeviceType } = useContext(DeviceAuthContext);
  const { tenant } = useParams();
  const [selectedSpace, setSelectedSpace] = useState('');

  const handelClickContinue = async () => {
    if (selectedSpace.indexOf(SpaceType.RECEPTION) !== -1) {
      try {
        const dto = { type: deviceType, zone: null };
        const href = await devicesConnect(dto);
        window.location.replace(href);
      } catch (err) {
        console.error(err);
      }
    } else {
      navigate(PATH_APP.space(tenant));
    }
  };

  return (
    <MainLayout>
      <div className="device-types wrapper">
        <h1 className="win-title">
          <Trans i18nKey="device_connection" />
        </h1>
        <p className="win-description">
          <Trans i18nKey="device-types-description" />
        </p>

        <div className="device-types__devices">
          <div
            onClick={() => {
              setDeviceType(DeviceType.PANEL);
              setSelectedSpace(`${DeviceType.PANEL}.${SpaceType.MEETING_ROOM}`);
            }}
            className={`device-types__devices__type ${
              selectedSpace === `${DeviceType.PANEL}.${SpaceType.MEETING_ROOM}` ? 'active' : ''
            }`}
          >
            <div className="device-types__devices__type__image">
              <img src={`${env.publicUrl}/images/panel-meeting-room.png`} alt="" />
            </div>
            <div className="device-types__devices__type__description">
              <p className="device-types__devices__type__name">
                <Trans i18nKey="panel_meeting-room" />
              </p>
              <p className="device-types__devices__type__text">
                <Trans i18nKey="panel_meeting-room_text" />
              </p>
            </div>
            <OkIcon
              className={`device-types__devices__type__ok-icon ${
                selectedSpace === `${DeviceType.PANEL}.${SpaceType.MEETING_ROOM}` ? 'active' : ''
              }`}
            />
          </div>

          <div
            onClick={() => {
              setDeviceType(DeviceType.TABLET);
              setSelectedSpace(`${DeviceType.TABLET}.${SpaceType.MEETING_ROOM}`);
            }}
            className={`device-types__devices__type ${
              selectedSpace === `${DeviceType.TABLET}.${SpaceType.MEETING_ROOM}` ? 'active' : ''
            }`}
          >
            <div className="device-types__devices__type__image">
              <img src={`${env.publicUrl}/images/tablet-meeting-room.png`} alt="" />
            </div>
            <div className="device-types__devices__type__description">
              <p className="device-types__devices__type__name">
                <Trans i18nKey="tablet_meeting-room" />
              </p>
              <p className="device-types__devices__type__text">
                <Trans i18nKey="tablet_meeting-room_text" />
              </p>
            </div>
            <OkIcon
              className={`device-types__devices__type__ok-icon ${
                selectedSpace === `${DeviceType.TABLET}.${SpaceType.MEETING_ROOM}` ? 'active' : ''
              }`}
            />
          </div>

          <div
            onClick={() => {
              setDeviceType(DeviceType.PHONE);
              setSelectedSpace(DeviceType.PHONE);
            }}
            className={`device-types__devices__type ${selectedSpace === DeviceType.PHONE ? 'active' : ''}`}
          >
            <div className="device-types__devices__type__image">
              <img src={`${env.publicUrl}/images/phone-meeting-room.png`} alt="" />
            </div>
            <div className="device-types__devices__type__description">
              <p className="device-types__devices__type__name">
                <Trans i18nKey="phone" />
              </p>
              <p className="device-types__devices__type__text">
                <Trans i18nKey="phone_text" />
              </p>
            </div>
            <OkIcon
              className={`device-types__devices__type__ok-icon ${selectedSpace === DeviceType.PHONE ? 'active' : ''}`}
            />
          </div>
          <div
            onClick={() => {
              setDeviceType(DeviceType.PANEL);
              setSelectedSpace(`${DeviceType.PANEL}.${SpaceType.RECEPTION}`);
            }}
            className={`device-types__devices__type ${
              selectedSpace === `${DeviceType.PANEL}.${SpaceType.RECEPTION}` ? 'active' : ''
            }`}
          >
            <div className="device-types__devices__type__image">
              <img src={`${env.publicUrl}/images/panel-reception.png`} alt="" />
            </div>
            <div className="device-types__devices__type__description">
              <p className="device-types__devices__type__name">
                <Trans i18nKey="panel_reception" />
              </p>
              <p className="device-types__devices__type__text">
                <Trans i18nKey="panel_reception_text" />
              </p>
            </div>
            <OkIcon
              className={`device-types__devices__type__ok-icon ${
                selectedSpace === `${DeviceType.PANEL}.${SpaceType.RECEPTION}` ? 'active' : ''
              }`}
            />
          </div>
          <div
            onClick={() => {
              setDeviceType(DeviceType.TABLET);
              setSelectedSpace(`${DeviceType.TABLET}.${SpaceType.RECEPTION}`);
            }}
            className={`device-types__devices__type ${
              selectedSpace === `${DeviceType.TABLET}.${SpaceType.RECEPTION}` ? 'active' : ''
            }`}
          >
            <div className="device-types__devices__type__image">
              <img src={`${env.publicUrl}/images/tablet-reception.png`} alt="" />
            </div>
            <div className="device-types__devices__type__description">
              <p className="device-types__devices__type__name">
                <Trans i18nKey="tablet_reception" />
              </p>
              <p className="device-types__devices__type__text">
                <Trans i18nKey="tablet_reception_text" />
              </p>
            </div>
            <OkIcon
              className={`device-types__devices__type__ok-icon ${
                selectedSpace === `${DeviceType.TABLET}.${SpaceType.RECEPTION}` ? 'active' : ''
              }`}
            />
          </div>
        </div>

        <ActionButton disabled={!deviceType} onClick={handelClickContinue}>
          <Trans i18nKey="continue" />
        </ActionButton>
      </div>
    </MainLayout>
  );
};

export default DeviceTypesPage;
