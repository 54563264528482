import React from 'react';
import './styles.scss';

const MainLayout = ({ children }) => {
  return (
    <div className="outer-wrapper">
      <div className="outer-wrapper__inner-wrapper">{children}</div>
    </div>
  );
};

export default MainLayout;
