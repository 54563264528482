import React from 'react';

const SearchIcon = (props) => {
  return (
    <svg width="33" height="33" viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M20.5549 18.7222H19.6771L19.366 18.4222C20.4549 17.1556 21.1105 15.5111 21.1105 13.7222C21.1105 9.73333 17.8771 6.5 13.8882 6.5C9.89935 6.5 6.66602 9.73333 6.66602 13.7222C6.66602 17.7111 9.89935 20.9444 13.8882 20.9444C15.6771 20.9444 17.3216 20.2889 18.5882 19.2L18.8882 19.5111V20.3889L24.4438 25.9333L26.0993 24.2778L20.5549 18.7222ZM13.8882 18.7222C11.1216 18.7222 8.88824 16.4889 8.88824 13.7222C8.88824 10.9556 11.1216 8.72222 13.8882 8.72222C16.6549 8.72222 18.8882 10.9556 18.8882 13.7222C18.8882 16.4889 16.6549 18.7222 13.8882 18.7222Z"
        fill="#A1AEB7"
      />
    </svg>
  );
};

export default SearchIcon;
