import React from 'react';

const ChevronRightIcon = () => {
  return (
    <svg width="7" height="13" viewBox="0 0 7 13" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.00057785 1.81855L1.2586 0.5L7 6.49479L1.25918 12.5L-5.75826e-08 11.1827L4.48032 6.496L0.00057785 1.81855Z"
        fill="#191919"
      />
    </svg>
  );
};

export default ChevronRightIcon;
