import React from 'react';

const OkIcon = (props) => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <rect width="32" height="32" fill="white" rx="50%" ry="50%" />
      <path
        d="M16 0C7.164 0 0 7.164 0 16C0 24.836 7.164 32 16 32C24.836 32 32 24.836 32 16C32 7.164 24.836 0 16 0ZM24.9427 12.9427L14.8627 23.0227C14.612 23.2733 14.2733 23.4133 13.92 23.4133C13.5667 23.4133 13.2267 23.2733 12.9773 23.0227L8.37333 18.4187C7.852 17.8973 7.852 17.0547 8.37333 16.5333C8.89467 16.012 9.73733 16.012 10.2587 16.5333L13.92 20.1947L23.0573 11.0573C23.5787 10.536 24.4213 10.536 24.9427 11.0573C25.464 11.5787 25.464 12.4213 24.9427 12.9427Z"
        fill="#82D94C"
      />
    </svg>
  );
};

export default OkIcon;
