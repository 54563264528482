import React from 'react';
import './localization';
import './styles/reset.scss';
import { Router } from './routes';
import DevicesAuthProvider from './providers/DevicesAuthProvider';

function App() {
  return (
    <DevicesAuthProvider>
      <Router />
    </DevicesAuthProvider>
  );
}

export default App;
