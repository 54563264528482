import React from 'react';
import './styles.scss';

const ActionButton = ({ type, disabled, onClick, children, ...props }) => {
  return (
    <button
      type={type}
      onClick={onClick}
      disabled={disabled}
      className={`action-button ${disabled ? 'disabled' : ''}`}
      {...props}
    >
      {children}
    </button>
  );
};

export default ActionButton;
