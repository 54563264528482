import React, { useState, useRef, useEffect } from 'react';
import useDebounce from '../../../hooks/useDebounce';
import SearchIcon from '../../../icons/SearchIcon';
import './styles.scss';

const SearchField = ({ label, value, onChange }) => {
  const inputRef = useRef(null);
  const [inputValue, setInputValue] = useState(value || '');
  const debouncedSearch = useDebounce(inputValue, 100);

  useEffect(() => {
    onChange(inputValue);
  }, [debouncedSearch]);

  return (
    <div className="search-field" onClick={() => inputRef.current && inputRef.current.focus()}>
      <SearchIcon />
      <input
        ref={inputRef}
        className="search-field__input"
        placeholder={label || ''}
        onChange={(event) => setInputValue(event.target.value)}
        value={inputValue}
      />
    </div>
  );
};

export default SearchField;
