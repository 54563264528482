import React, { useContext, useState } from 'react';
import MainLayout from '../../components/MainLayout';
import { Trans } from 'react-i18next';
import '../../styles/common-styles.scss';
import './styles.scss';
import TextField from '../../components/TextField';
import ActionButton from '../../components/ActionButton';
import { useParams } from 'react-router-dom';
import { DeviceAuthContext } from '../../providers/DevicesAuthProvider';
import { apiCredentials } from '../../services/http';
import { PulseLoader } from 'react-spinners';
import { authDeviceManager } from '../../services/http/requests';

const PasswordPage = () => {
  const { setIsAuthenticated } = useContext(DeviceAuthContext);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [password, setPassword] = useState('');
  const { tenant } = useParams();

  const handlePasswordChange = (evt) => {
    setPassword(evt.target.value);
    if (isError) {
      setIsError(false);
    }
  };
  const handleSubmit = async (evt) => {
    evt.preventDefault();
    setIsLoading(true);
    setIsError(false);
    try {
      const accessToken = await authDeviceManager(tenant, password);
      await apiCredentials.setTokens(accessToken, null);
      setIsAuthenticated(true);
    } catch (err) {
      console.error(err);
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <MainLayout>
      <form className="password-main wrapper" onSubmit={handleSubmit} noValidate>
        <h1 className="win-title">
          <Trans i18nKey="device_connection" />
        </h1>
        <p className="win-description">
          <Trans i18nKey="password_description" />
        </p>

        <TextField
          type={'password'}
          value={password}
          onChange={handlePasswordChange}
          label={<Trans i18nKey="password" />}
        />

        {isError && (
          <p className="password__error">
            <Trans i18nKey={'password_error'} />
          </p>
        )}

        <ActionButton type={'submit'} disabled={isLoading || !password}>
          {isLoading && <PulseLoader color={'#fff'} />}
          {!isLoading && <Trans i18nKey="continue" />}
        </ActionButton>
      </form>
    </MainLayout>
  );
};

export default PasswordPage;
