import React, { useRef, useState } from 'react';
import './styles.scss';

const TextField = ({ type, value, label, onChange, ...props }) => {
  const [inputValue, setInputValue] = useState(value || '');
  const [inputFocused, setInputFocused] = useState(false);
  const wrapperRef = useRef(null);
  const inputRef = useRef(null);

  const onInput = (event) => {
    if (event.target) {
      setInputValue(event.target.value);
      onChange && onChange(event);
    }
  };

  return (
    <div
      ref={wrapperRef}
      onClick={() => {
        inputRef.current && inputRef.current.focus();
      }}
      className="text-field"
    >
      <p className={`text-field__label ${inputValue || inputFocused ? 'focused' : ''}`}>
        {label || (props?.placeholder && (label || props?.placeholder))}
      </p>
      <input
        type={type}
        ref={inputRef}
        value={inputValue}
        onFocus={() => setInputFocused(true)}
        onBlur={() => setInputFocused(false)}
        className="text-field__input"
        onChange={onInput}
        {...props}
      />
    </div>
  );
};

export default TextField;
